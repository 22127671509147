import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../utils/Modal";

import { ReactComponent as Shadow } from "../assets/sensei/levitating-shadow.svg";
import { ReactComponent as RubyGem } from "../assets/gems/ruby.svg";
import { ReactComponent as CitrineGem } from "../assets/gems/citrine.svg";
import { ReactComponent as TopazGem } from "../assets/gems/topaz.svg";
import { ReactComponent as EmeraldGem } from "../assets/gems/emerald.svg";
import { ReactComponent as AquamarineGem } from "../assets/gems/aquamarine.svg";
import { ReactComponent as TanzaniteGem } from "../assets/gems/tanzanite.svg";
import { ReactComponent as AmethystGem } from "../assets/gems/amethyst.svg";
import { ReactComponent as DownArrow } from "../assets/icons/arrows/down.svg";
import { useOnScreen } from "../hooks";
import LevitatingSensei from "../components/LevitatingSensei";
import { showMeTheWay } from "../utils/helpers";
import GPhone from "../components/animated/GPhone";

function HeroHome({ phoneRef }) {
  const [deckModalOpen, setDeckModalOpen] = useState(false);
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [isVideoStarted, setIsVideoStarted] = useState(false);

  const history = useHistory();
  const isPhoneVisible = useOnScreen(phoneRef);
  const [isTypingComplete, setIsTypingComplete] = useState(false);
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(1);
  }, [count]);

  return (
    <section>
      <div
        className="absolute left-0 bottom-0 -ml-20 hidden lg:block pointer-events-none"
        aria-hidden="true"
        data-aos="fade-up"
        data-aos-delay="400"
      ></div>

      <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
        {/* Illustration behind hero content */}

        {/* Hero content */}
        <div className="relative pt-32 pb-10 md:pt-40 md:pb-16">
          {/* Section header */}
          <div className="mx-auto text-center pb-12 md:pb-16">
            {/* Desktop and Tablet */}
            <div className="relative md:flex hidden justify-center items-center w-full ">
              <div className="flex flex-col w-3/5 ">
                <div
                  className="text-6xl h4 mb-3 text-white text-left font-bold"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  Next-Gen Generosity: Earn While You Help Generate Revenue
                  for Non-Profits...
                </div>
                <div
                  className="text-left mt-3 md:text-3xl text-xl text-white"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  {/* Generos
                  <span className="italic font-bold text-primary-1 mr-2">
                    {` empowers givers `}
                  </span>{" "}
                  to multiply their resources, champion great causes and earn in
                  the process. */}
                  Contributing to causes shouldn’t feel so one-sided — earn
                  rewards for supporting non-profits and their missions.
                </div>
                <div
                  className="flex justify-start mt-12"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <a
                    className="btn md:text-lg rounded-lg font-bold text-primary-0 bg-primary-4 hover:bg-gray-800 w-full  sm:w-auto sm:mb-0"
                    href={`${process.env.REACT_APP_GENEROS_APP_URI}/install`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex items-center">
                      <GPhone
                        className="fill-current text-white w-12 -ml-4"
                        heartFill="fill-current text-primary-1"
                      />
                      <div className="flex flex-col text-left ml-4 leading-tight">
                        <div className="text-base font-thin text-white">
                          Install Instructions
                        </div>
                        <div className="text-2xl font-semibold text-white">
                          Get the App
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div
                className="flex flex-col justify-center w-1/2"
                data-aos="fade-up"
                data-aos-delay="1000"
              >
                {/* <LevitatingSensei className="animate-levitate" />
                <Shadow className="px-12 animate-stretchShadow" /> */}
                {/* <PhoneProfile /> */}
                <img
                  className="w-full md:px-10"
                  src={require(`../images/phone/peek.png`).default}
                  alt="phone-profile"
                />
              </div>
            </div>
            {/* Mobile */}
            <div className="relative justify-between w-full">
              <div className="md:hidden flex flex-col justify-center items-center">
                <div
                  className="flex flex-col text-xxl h1 md:leading-normal mb-6 text-white text-center"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  {/* <p>Tired of One-Sided Giving?</p>
                  <p>Generate Revenue for Non-Profits and Earn Rewards...</p> */}
                  <p>Next-Gen Generosity:</p>
                  <p>Earn While You Help</p>
                  <p>Generate Revenue for</p>
                  <p>Non-Profits...</p>
                </div>
                <div
                  className="text-2xl text-white text-center mb-6"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  {/* Generos
                  <span className="italic font-bold text-primary-1 mr-2">
                    {` empowers givers `}
                  </span>{" "}
                  to multiply their resources, champion great causes and earn in
                  the process. */}
                  Contributing to causes shouldn’t feel so one-sided - earn
                  rewards for supporting non-profits and their missions.
                </div>
                <div
                  className="flex flex-col justify-center w-full mt-6"
                  data-aos="fade-up"
                  data-aos-delay="1000"
                >
                  {/* <PhoneProfile /> */}
                  <img
                    className="w-full md:px-10"
                    src={require(`../images/phone/peek.png`).default}
                    alt="phone-profile"
                  />
                </div>

                <div
                  className="flex justify-start mt-12"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <a
                    className="btn md:text-lg rounded-lg font-bold p-4 text-primary-0 bg-primary-4 hover:bg-gray-800 w-full mb-4 sm:w-auto sm:mb-0"
                    href={`${process.env.REACT_APP_GENEROS_APP_URI}/install`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="flex items-center">
                      <GPhone
                        className="fill-current text-white w-12"
                        heartFill="fill-current text-primary-1"
                      />
                      <div className="flex flex-col text-left px-4 leading-tight">
                        <div className="text-base font-thin text-white">
                          Install Instructions
                        </div>
                        <div className="text-2xl font-semibold text-white">
                          Get the App
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Hero image */}
          <div
            className={`absolute bottom-0 md:flex justify-center w-full hidden mt-24 ${
              isPhoneVisible ? "opacity-0" : ""
            }`}
          >
            <DownArrow className="animate-bounce w-16 fill-current text-primary-1" />
          </div>
          <div
            className="flex justify-between md:mx-20 mx-6 mb-8"
            data-aos-id-gems-anchor
          >
            <RubyGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="400"
              ref={phoneRef}
            />
            <CitrineGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="800"
            />
            <TopazGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="1200"
            />
            <EmeraldGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="1600"
            />
            <AquamarineGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="2000"
            />
            <TanzaniteGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="2400"
            />
            <AmethystGem
              className="md:w-12 w-6"
              data-aos="fade-up"
              data-aos-anchor="[data-aos-id-gamified-anchor]"
              data-aos-delay="2800"
            />
          </div>

          <Modal
            id="modal"
            ariaLabel="modal-headline"
            show={deckModalOpen}
            handleClose={() => setDeckModalOpen(false)}
          >
            <div className="reFlative pb-9/16">
              <iframe
                src="https://slides.com/patricklewis/generos-io/embed?token=FCRoUuML&style=hidden"
                className="absolute w-full h-full"
                title="Generos (IO)"
                scrolling="no"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                allowFullScreen
              ></iframe>
            </div>
          </Modal>
        </div>
      </div>
    </section>
  );
}

export default HeroHome;
