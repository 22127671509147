import React, { useState } from "react";
import { ReactComponent as GencoinsIcon } from "../assets/gencoins.svg";
import { ReactComponent as GencoinGemsIcon } from "../assets/gencoin-gems.svg";
import { ReactComponent as VaultSVG } from "../assets/unlocked-vault.svg";
import CountUp from "react-countup";
// import { ReactComponent as GLogo } from "../assets/gear-heart.svg";
import Modal from "../utils/Modal";
// import Flow from "../partials/Flow";

function Process() {
  const [videoModalOpen, setVideoModalOpen] = useState(false);
  const [isVideoStarted, setIsVideoStarted] = useState(false);
  return (
    <section>
      <div
        className="max-w-6xl mx-auto px-4 sm:px-6 items-center"
        data-aos-id-gen-method-anchor
      >
        <div className="md:py-20 border-t border-gray-800">
          <div className="">
            {/* Section header */}
            <div className="flex flex-col max-w-3xl mx-auto text-center items-center mt-24 mb-20">
              <div
                className="text-4xl h4 mb-6 text-white text-center"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                Generosity that generates rewards...
              </div>
              <div
                className="text-4xl font-extrabold text-primary-1 mt-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                How It Works
              </div>
              <div className="flex justify-center w-full" data-aos="fade-up">
                <VaultSVG
                  className="w-5/12 mt-10"
                  data-aos="fade-up"
                  data-aos-delay="600"
                />
              </div>
            </div>
            {/* Mobile */}
            <div className="md:hidden flex flex-col items-center">
              <div
                className="flex flex-col items-center text-center justify-center w-full mb-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                {/* <ValueCreationIcon className="w-36 mb-4 text-primary-1 fill-current" /> */}

                <div className="text-xxl h1 mb-2 text-white">
                  Collect Vaults
                </div>
                <p className="text-2xl text-white mb-10">
                  Begin your Generos journey by collecting digital vaults. Each
                  vault holds the potential to generate Gencoin - a community
                  token each valued at{" "}
                  <span className="font-bold">1 US Dollar</span>.
                </p>
                <p className="text-2xl text-white mb-10">
                  You are rewarded a vault each time you purchase a gem for
                  another user, aiding their fundraising efforts and enhancing
                  your impact and reach.
                </p>
                <img
                  className="w-full md:w-3/6 md:px-10 mb-10"
                  src={require(`../images/phone/collect.png`).default}
                  alt="phone-orgs"
                />
              </div>
              <div
                className="flex flex-col text-center items-center justify-center w-full mb-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="text-xxl h1 mb-2 text-white">
                  Generate Gencoin
                </div>
                <p className="text-2xl text-white mb-10">
                  When your vaults fill with gems, they generate at least{" "}
                  <span className="font-bold">10 Gencoin</span>, which you can
                  use to support causes or convert into cash.
                </p>
                <p className="text-2xl text-white mb-10">
                  Each gem gifted to a vault also strengthens its connections,
                  increasing its chances of receiving more gems at no extra
                  effort through our dynamic algorithm, helping you generate
                  even more Gencoin.
                </p>
                <img
                  className="w-full md:w-3/6 md:px-10 mb-10"
                  src={require(`../images/phone/generate.png`).default}
                  alt="phone-orgs"
                />
              </div>
              <div
                className="flex flex-col items-center text-center justify-center w-full mb-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="text-xxl h1 mb-2 text-white">
                  Contribute to Causes
                </div>

                <p className="text-2xl text-white mb-10">
                  Put your Gencoin to work by supporting meaningful causes,
                  helping others reach their goals, and growing the spirit of
                  generosity within the community.
                </p>
                <p className="text-2xl text-white mb-10">
                  Your contributions fuel a cycle of giving that benefits you
                  and the entire Generos ecosystem.
                </p>
                <img
                  className="w-full md:w-3/6 md:px-10 mb-10"
                  src={require(`../images/phone/contribute.png`).default}
                  alt="phone-orgs"
                />
              </div>
            </div>

            {/* Desktop and Tablet */}
            <div className="hidden md:flex flex-col">
              <div
                className="flex mb-36"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="flex flex-col items-start justify-center pr-6">
                  {/* <div className="flex w-full items-center justify-center mb-10">
                    <ValueCreationIcon className="w-36 mb-4 text-primary-1 fill-current" />
                  </div> */}

                  <div className="text-xxl h1 mb-2 text-white">
                    Collect Vaults
                  </div>
                  <p className="text-3xl text-white mb-10">
                    Begin your Generos journey by collecting digital vaults.
                    Each vault holds the potential to generate Gencoin - a
                    community token each valued at{" "}
                    <span className="font-semibold">1 US Dollar</span>.
                  </p>
                  <p className="text-3xl text-white mb-10">
                    You are rewarded a vault each time you purchase a gem for
                    another user, aiding their fundraising efforts and enhancing
                    your impact and reach.
                  </p>
                </div>

                <img
                  className="w-3/6"
                  src={require(`../images/phone/collect.png`).default}
                  alt="phone-orgs"
                />
              </div>
              <div
                className="flex mb-36"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <img
                  className="w-3/6"
                  src={require(`../images/phone/generate.png`).default}
                  alt="phone-orgs"
                />
                {/* <VaultSVG className="mr-6" /> */}

                <div className="flex flex-col items-start justify-center pl-6">
                  {/* <div className="flex w-full items-center justify-center mb-10">
                    <GencoinIcon className="w-36 mb-4 text-primary-1 fill-current" />
                  </div> */}
                  <div className="text-xxl h1 mb-2 text-white">
                    Generate Gencoin
                  </div>
                  <p className="text-3xl text-white mb-10">
                    When your vaults fill with gems, they generate at least{" "}
                    <span className="font-semibold">10 Gencoin</span>, which you
                    can use to support causes or convert into cash.
                  </p>
                  <p className="text-3xl text-white mb-10">
                    Each gem gifted to a vault also strengthens its connections,
                    increasing its chances of receiving more gems at no extra
                    effort through our dynamic algorithm, helping you generate
                    even more Gencoin.
                  </p>
                </div>
              </div>
              <div
                className="flex mb-10"
                data-aos="fade-up"
                data-aos-delay="500"
              >
                <div className="flex flex-col items-start justify-center pr-6">
                  {/* <div className="flex w-full items-center justify-center mb-10">
                    <GrowIcon className="w-36 mb-4 text-primary-1 fill-current" />
                  </div> */}

                  <div className="text-xxl h1 mb-2 text-white">
                    Contribute to Causes
                  </div>
                  <p className="text-3xl text-white mb-10">
                    Put your Gencoin to work by supporting meaningful causes,
                    helping others reach their goals, and growing the spirit of
                    generosity within the community.
                  </p>
                  <p className="text-3xl text-white mb-10">
                    Your contributions fuel a cycle of giving that benefits you
                    and the entire Generos ecosystem.
                  </p>
                </div>

                <img
                  className="w-3/6"
                  src={require(`../images/phone/contribute.png`).default}
                  alt="phone-orgs"
                />
              </div>
            </div>

            {/* <div className="flex flex-col max-w-3xl mx-auto text-center items-center mt-10 mb-20">
              <div className="flex justify-center w-full" data-aos="fade-up">
                <GencoinsIcon className="w-5/12 mt-10" />
              </div>
            </div> */}

            {/* Explainer video */}
            {/* <div className="mb-12">
              <div
                className="relative flex justify-center items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <img
                  className="mx-auto"
                  src={require("../images/scene-2-splash.png").default}
                  width="524"
                  height="104"
                  alt="Hero"
                />
                <a
                  className="absolute group"
                  href="#0"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setIsVideoStarted(true);
                    setVideoModalOpen(true);
                  }}
                  aria-controls="modal"
                >
                  <svg
                    className="w-16 h-16 sm:w-20 sm:h-20 hover:opacity-75 transition duration-150 ease-in-out"
                    viewBox="0 0 88 88"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <defs>
                      <linearGradient
                        x1="78.169%"
                        y1="9.507%"
                        x2="24.434%"
                        y2="90.469%"
                        id="a"
                      >
                        <stop
                          stopColor="#EBF1F5"
                          stopOpacity=".8"
                          offset="0%"
                        />
                        <stop stopColor="#EBF1F5" offset="100%" />
                      </linearGradient>
                    </defs>
                    <circle fill="url(#a)" cx="44" cy="44" r="44" />
                    <path
                      className="fill-current text-purple-600"
                      d="M52 44a.999.999 0 00-.427-.82l-10-7A1 1 0 0040 37V51a.999.999 0 001.573.82l10-7A.995.995 0 0052 44V44c0 .001 0 .001 0 0z"
                    />
                  </svg>
                </a>
              </div>

              <div
                className="text-center text-primary-1 font-extrabold font-barlow italic text-2xl mt-6"
                data-aos-delay="2800"
              >
                An Algorithm Powered By Digital Generosity
              </div>

              <Modal
                id="modal"
                ariaLabel="modal-headline"
                show={videoModalOpen}
                handleClose={() => {
                  setIsVideoStarted(false);
                  setVideoModalOpen(false);
                }}
              >
                <div className="relative pb-9/16">
                  <iframe
                    className="absolute w-full h-full"
                    src={
                      isVideoStarted && "https://youtube.com/embed/pdvWZzXAKZE"
                    }
                    title="Video"
                    allowFullScreen
                  ></iframe>
                </div>
              </Modal>
            </div> */}
          </div>
        </div>
      </div>
      {/* <Flow /> */}
    </section>
  );
}

export default Process;
