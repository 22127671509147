import React from "react";

import Header from "../partials/Header";
import { useHistory } from "react-router-dom";

import Footer from "../partials/Footer";
import { ReactComponent as Karma } from "../assets/karma.svg";
import { joinWaitList } from "../utils/helpers";
import GPhone from "../components/animated/GPhone";
import CTA from "../partials/CTA";

function TheGenerosManifesto() {
  const history = useHistory();
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="grow">
        <section>
          <div className="max-w-6xl mx-auto px-4 sm:px-6 relative">
            {/* Section header */}
            <div className="max-w-3xl mx-auto text-left pb-12 md:pb-20 ">
              <div className="max-w-3xl mx-auto text-center pb-12">
                <div
                  className="text-white md:text-4xl text-3xl h1 mt-28"
                  data-aos="fade-up"
                >
                  The Generos Manifesto
                </div>
              </div>
              <div className="flex items-center justify-center w-full">
                <Karma
                  className="w-24 mb-6"
                  data-aos="fade-up"
                  data-aos-delay="200"
                />
              </div>
              <div
                className="text-white md:text-3xl text-2xl mt-12 whitespace-pre-line"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <p className="mb-10">
                  Something's broken in charitable fundraising.
                </p>
                <p className="mb-10">
                  It used to be about genuine connection, about real people
                  helping others in need. Gone are the days when giving felt
                  personal, when each contribution felt like a direct lifeline
                  to those in need. Now, many hesitate to give, thinking their
                  small acts don't count. But what if every contribution, no
                  matter the size, could make a real difference?
                </p>
                <p className="mb-10">
                  Introducing Generos - a generosity revolution.
                </p>
                <p className="mb-10">
                  Generos confronts the myth of 'not enough.' Every gesture of
                  kindness counts, and the benefits of giving are profound –
                  better health, stronger relationships, financial well-being.
                </p>
                <p className="mb-10">
                  <p>
                    <span className="italic font-semibold text-primary-1">
                      {` Generative Generosity -`}
                    </span>{" "}
                    We turn every act of giving into an opportunity for growth
                    and impact. Generos isn't just about giving; it's about
                    celebrating and rewarding generosity.
                  </p>
                </p>
                <p className="mb-10">
                  <span className="italic font-semibold text-primary-1">
                    {`Equitable Participation -`}
                  </span>{" "}
                  Generos redefines fundraising participation. We ensure every
                  giver, regardless of their financial means, not only
                  contributes but also shares in the upside of the fundraising
                  efforts. It's a model where giving becomes a shared journey,
                  with tangible benefits for all involved.
                </p>
                <p className="mb-10">
                  Generosity is a habit that grows with practice, and effective
                  charitable fundraising must be inclusive. Generos isn't just a
                  platform; it's a community where each small act weaves into a
                  bigger story of change.
                </p>
                <p className="mb-10">
                  Welcome to Generos, where your generosity is celebrated, your
                  impact felt, and your role as a giver redefined.
                </p>
                <p className="mb-10">
                  The revolution will be digitized, so join us in rewriting the
                  story of generosity.
                </p>
                <p className="mb-10">Be a radical changemaker.</p>
                <p className="mb-10 it">
                  ⚙️{" "}
                  <span className="italic">
                    Unlock The Generosity Algorithm
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
        <CTA />
      </main>

      {/*  Site footer */}
      <Footer />
    </div>
  );
}

export default TheGenerosManifesto;
