import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

function Timeline() {
  const items = [
    {
      date: "Dec 2017 - Dec 2019",
      cardTitle: "KUUMBA",
      cardSubtitle: "(Creativity)",
      isCompleted: true,
      points: [
        "concept ideation",
        "core algorithm design",
        "initial server development",
      ],
    },
    {
      date: "Jan 2020 - Jun 2021",
      cardTitle: "IMANI",
      cardSubtitle: "(Faith)",
      isCompleted: true,
      points: [
        "initial prototype development",
        "sandbox release",
        "alpha release",
      ],
    },
    {
      date: "Jul 2021 - Present",
      isCurrentPhase: true,
      cardTitle: "UBUNTU",
      cardSubtitle: "(Community)",
      isCompleted: false,
      points: ["beta release", "blockchain integration", "community building"],
    },
    {
      date: "TBD",
      cardTitle: "UJAMAA",
      cardSubtitle: "(Cooperative Economics)",
      isCompleted: false,
      points: [
        "go-to-market strategy",
        "strategic partnerships",
        "seed funding",
      ],
    },
    {
      date: "TBD",
      cardTitle: "UJIMA",
      cardSubtitle: "(Collective Work)",
      isCompleted: false,
      points: [
        "educational content integration",
        "nft avatar marketplace",
        "general release",
      ],
    },
    {
      date: "TBD",
      cardTitle: "UMOJA",
      cardSubtitle: "(Unity)",
      isCompleted: false,
      points: ["defi platform innovation", "complete on-chain migration"],
    },
  ];

  const getPointColor = ({ date, isCurrentPhase }) => {
    if (date === "TBD") return "#2f2f4d";
    if (!!isCurrentPhase) return "#94d154";

    return "#848aff";
  };

  const getDateColor = ({ date, isCurrentPhase }) => {
    if (date === "TBD") return "text-primary-2";
    if (!!isCurrentPhase) return "text-emerald-500 animate-pulse";
    return "text-topaz-500";
  };

  return (
    <section>
      <div data-aos="fade-up">
        <VerticalTimeline lineColor="#2f2f4d">
          {items.map(
            ({
              date,
              cardTitle,
              cardSubtitle,
              points,
              isCompleted,
              isCurrentPhase,
            }) => (
              <VerticalTimelineElement
                key={cardTitle}
                lineColor="#2f2f4d"
                className=""
                contentStyle={{ background: "#2f2f4d", color: "#fff" }}
                contentArrowStyle={{
                  borderRight: "7px solid  #2f2f4d",
                }}
                iconStyle={{
                  background: getPointColor({ date, isCurrentPhase }),
                  color: "#fff",
                  borderColor: "2f2f4d",
                }}
              >
                <div className="font-extrabold font-ubuntu text-primary-1 text-2xl">
                  {cardTitle}
                  <span className="text-white font-barlow font-extrabold text-xl ml-2">
                    {cardSubtitle}
                  </span>
                </div>
                <div
                  className={`mb-6 font-extrabold italic ${getDateColor({
                    date,
                    isCurrentPhase,
                  })}`}
                >
                  {date}
                </div>
                {points.map((point, index) => (
                  <div
                    key={index}
                    className="flex text-lilac-200 text-lg  items-top font-semibold mb-2"
                  >
                    {isCompleted ? (
                      <svg
                        className="w-3 h-3 fill-current text-emerald-500 mr-3 mt-2"
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                    ) : (
                      <span className="mr-2">-</span>
                    )}
                    {point}
                  </div>
                ))}
              </VerticalTimelineElement>
            )
          )}
        </VerticalTimeline>
      </div>
    </section>
  );
}

export default Timeline;
