import React from "react";
import GPhone from "../components/animated/GPhone";

const CTA = () => {
  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 mb-20">
        {/* CTA box */}
        <div
          className="flex w-full items-center justify-center rounded-lg"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <div className="flex w-full justify-center">
            <div
              className="flex md:w-4/12 w-full font-extrabold rounded-lg bg-purple-600 hover:bg-purple-700 text-xl p-6 text-topaz-500 cursor-pointer"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_GENEROS_APP_URI}/install`,
                  "_blank"
                );
              }}
            >
              <div className="flex items-center">
                <GPhone
                  className="fill-current text-white w-20 -ml-4"
                  heartFill="fill-current text-white"
                />
                <div className="flex flex-col text-left">
                  <div className="text-3xl font-semibold text-white">
                    Get the App
                  </div>
                  <div className="text-lg font-semibold text-white italic">
                    Join the Generos Community
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
